import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';

import {
    trackEvent,
    trackEcommerce,
    trackingConstants,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
import { ItemTile } from 'dibs-search-product-tile/exports/ItemTile';
import ItemHeartWrapper from 'dibs-portfolio-heart/exports/ItemHeartWrapper';
import ItemFolderWrapper from 'dibs-portfolio-folder/exports/ItemFolderWrapper';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { addToEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';
import FavoritesItemProvider from 'dibs-portfolio-folder/exports/FavoritesItemProvider';

import { useServerVarsContext } from '../../../sharedComponents/ServerVarsContext';
import folderTracking from '../../helpers/storefrontFolderTracking';
import { storefrontEnsureLogin } from '../../mutations/storefrontEnsureLogin';

import styles from './styles/StorefrontProductTile.scss';

const { ECOM_PRODUCT_CLICK } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_WISHLIST } = eventNameConstants;
const { INTERACTION_TYPE_ITEM_FAVORITING } = interactionTypeConstants;
const { STEP_INTERACTION_ITEM_ADDED, STEP_INTERACTION_ITEM_REMOVED } = stepInteractionConstants;

function fireProductFavoriting(action, itemId, step_interaction_name) {
    const trigger = 'search/browse';
    const trackObj = {
        action,
        label: trigger,
        category: INTERACTION_TYPE_ITEM_FAVORITING,
        isInteractiveEvent: true,
        additional: itemId ? { itemId } : {},
        eventName: EVENT_WISHLIST,
        interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
        step_interaction_name,
        trigger,
    };

    trackEvent(trackObj, null);
    addToEngagedItems(itemId);
}
const StorefrontProductTile = ({
    relay,
    index,
    user,
    userId,
    userIds,
    viewer,
    product,
    activeQuickView,
    openQuickView,
    closeQuickView,
    currency,
    showCurrency,
}) => {
    const { isMobile } = useServerVarsContext();
    const { title, photos, serviceId: itemId } = product || {};
    const image = photos?.[0]?.versions?.[0]?.webPath;

    const getFavoritesComponent = ({
        isFolderVisible,
        showFolderPlusIcon,
        onFavorited,
        onUnFavorited,
        onFolderActionStart,
        onFolderActionEnd,
    }) => {
        const sharedIconClasses = classnames(styles.icon, {
            [styles.enlargedIcon]: !isMobile,
        });

        return (
            <div className={styles.favoritesWrapper}>
                {/* Heart */}
                <div
                    className={classnames(sharedIconClasses, {
                        [styles.favoritesSpacing]: !isMobile,
                    })}
                >
                    <ItemHeartWrapper
                        authModalShow={cb =>
                            storefrontEnsureLogin({
                                relayEnvironment: relay.environment,
                                authOptions: {
                                    ga: {
                                        label: 'save item - dealer storefront page',
                                    },
                                },
                            })
                                .then(cb)
                                .catch(() => {})
                        }
                        item={product}
                        loadPortfolioData={!!userId}
                        onFavorited={() => {
                            fireProductFavoriting(
                                'item added',
                                itemId,
                                STEP_INTERACTION_ITEM_ADDED
                            );
                            onFavorited?.();
                        }}
                        onUnFavorited={() => {
                            fireProductFavoriting(
                                'item removed',
                                itemId,
                                STEP_INTERACTION_ITEM_REMOVED
                            );
                            onUnFavorited?.();
                        }}
                        viewer={viewer}
                        userId={userId}
                        userIds={userIds}
                        theme="dark"
                    />
                </div>
                {/* Folder */}
                {!isMobile && isFolderVisible && (
                    <div className={sharedIconClasses}>
                        <ItemFolderWrapper
                            authModalTrackingLabel="save item - search/browse"
                            item={product}
                            selectedItemImages={image ? [image] : []}
                            selectedItemTitles={title ? [title] : []}
                            shouldFillInFolder={!!userId}
                            singlePortfolioItem
                            trackingHooks={folderTracking}
                            getModalComponent={() => {
                                return storefrontEnsureLogin({
                                    relayEnvironment: relay.environment,
                                    authOptions: {
                                        shouldPauseAuthFlow: state => state.step === 0,
                                    },
                                })
                                    .then(() => {
                                        return import(
                                            /* webpackChunkName: "AddToFolderModalWrapper" */
                                            'dibs-portfolio-folder/src/components/AddToFolderModalWrapper'
                                        ).then(exported => exported.default);
                                    })
                                    .catch(() => {});
                            }}
                            onAddItemSuccess={() => {
                                if (authModalLoader.isPaused()) {
                                    authModalLoader.resume();
                                }

                                onFolderActionEnd?.();
                            }}
                            userId={userId}
                            userIds={userIds}
                            viewer={viewer}
                            theme="dark"
                            showPlusIcon={showFolderPlusIcon}
                            onFolderSave={onFolderActionStart}
                            onFolderRemove={onFolderActionStart}
                            onNewFolderSave={onFolderActionStart}
                            onRemoveItemSuccess={onFolderActionEnd}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <FavoritesItemProvider item={product} user={user} applyMouseEvents={!isMobile}>
            {favoritesItemProps => (
                <ItemTile
                    index={index}
                    isMobile={isMobile}
                    item={product}
                    itemSearch={null}
                    itemsPerRow={4}
                    currency={currency}
                    showPrice={!!currency && showCurrency}
                    showSellerName={false}
                    onClick={() => {
                        trackEcommerce({
                            type: ECOM_PRODUCT_CLICK,
                            eventName: EVENT_SELECT_ITEM,
                            actionField: {
                                list: 'storefront featured listing',
                                itemId,
                            },
                            products: [product.ecommerceTrackingParams],
                        });
                    }}
                    showQuickView
                    activeQuickView={activeQuickView}
                    closeQuickView={closeQuickView}
                    openQuickView={openQuickView}
                    useLoFiLazyLoader
                    viewer={null}
                    favoritesComponent={getFavoritesComponent(favoritesItemProps)}
                    showMSKUSwatch={false}
                    showMeasurements={false}
                    imageSize={isMobile ? 'imageSmall' : 'imageMedium'}
                    hideImageSidePadding={isMobile}
                />
            )}
        </FavoritesItemProvider>
    );
};

StorefrontProductTile.propTypes = {
    openQuickView: PropTypes.func.isRequired,
    closeQuickView: PropTypes.func.isRequired,
    activeQuickView: PropTypes.number,

    relay: PropTypes.object.isRequired,

    // relay variabales
    userId: PropTypes.string,
    userIds: PropTypes.array,
    isTrade: PropTypes.bool,
    currency: PropTypes.string,
    showCurrency: PropTypes.bool,
    index: PropTypes.number.isRequired,

    // relay fragments
    viewer: PropTypes.object.isRequired,
    user: PropTypes.object,
    product: PropTypes.object.isRequired,
};

export default createFragmentContainer(StorefrontProductTile, {
    viewer: graphql`
        fragment StorefrontProductTile_viewer on Viewer {
            ...ItemHeartWrapper_viewer @arguments(loadPortfolioData: $hasUserId, userIds: $userIds)
        }
    `,
    user: graphql`
        fragment StorefrontProductTile_user on User {
            serviceId
            ...FavoritesItemProvider_user
        }
    `,
    product: graphql`
        fragment StorefrontProductTile_product on Item {
            ...ItemFolderWrapper_item @arguments(userId: $userId, loadPortfolioData: $hasUserId)
            ...ItemHeartWrapper_item @arguments(userId: $userId, loadPortfolioData: $hasUserId)
            ...ItemTile_item @arguments(isTrade: $isTrade, priceBookName: $priceBookName)
            ...FavoritesItemProvider_item
            serviceId
            title
            photos {
                versions {
                    webPath
                }
            }
            ecommerceTrackingParams
        }
    `,
});

export { StorefrontProductTile as Raw };
